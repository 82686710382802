<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";

export default {
  locales: {
    pt: {
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: {
    Layout,
    VclList
  },
  data() {
    return {
      contracts: {
        loading: true,
        errored: false,
        empty: true,
        list: null
      }
    };
  },
  methods: {
    showDate (date) {
      this.date = date
    },
    getContracts() {
      this.contracts.loading = true
      this.contracts.errored = false
      this.contracts.empty = false
      this.contracts.list = null

      api
        .get('contracts')
        .then(response => {
          if (response.data.status=='success') {
            this.contracts.list = response.data.list
          }
        })
        .catch(error => {
          this.contracts.errored = error
        })
        .finally(() => {
          this.contracts.loading = false
          if (this.contracts.list=='' || this.contracts.list==null) {
            this.contracts.empty = true
          }
        })
    },
  },
  mounted() {
    this.getContracts()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Meus Planos') }}</h4>
          <div class="page-title-right">
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="contracts.errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="contracts.empty">{{ t('No records found.') }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="contracts.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Plano</th>
                    <th>Data</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row,index) in contracts.list" :key="index">
                    <td class="notranslate align-middle">{{ row.id }}</td>
                    <td class="notranslate align-middle">{{ row.type }}</td>
                    <td class="notranslate align-middle">
                      <template v-if="row.date != '01/01/1970 00:00'">{{ row.date }}</template>
                      <template v-else>–</template>
                    </td>
                    <td class="notranslate align-middle">
                      <span v-if="row.status === 'inactive'" class="badge badge-soft-warning font-size-12">INATIVO</span>
                      <span v-else-if="row.status === 'active'" class="badge badge-soft-success font-size-12">ATIVO</span>
                    </td>
                    <td class="notranslate align-middle text-right">
                      <router-link :to="'/contracts/' + row.id" class="btn btn-primary btn-sm btn-rounded">
                        {{ t('Acessar') }}
                        <i class="mdi mdi-arrow-right ml-1"></i>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>